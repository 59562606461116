<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="account">
		<!-- <div class="account-data-add">
			<span @click="accountHandle('add')">+ 新增</span>
		</div> -->
		<div class="data-list-search">
			<div class="list-search-row">
				<div class="list-search-col">
					<span class="list-search-label">账号类型：</span>
					<el-select class="list-search-select" v-model="searchForm.userType" placeholder="请选择">
						<el-option v-for="(item, index) in typeList" :key="index" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="list-search-col">
					<span class="list-search-label">所属部门：</span>
					<el-select class="list-search-select" v-model="searchForm.orgId" placeholder="请选择">
						<el-option v-for="(item, index) in orgAllList" :key="index" :label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="list-search-col">
					<span class="list-search-label">所属校区：</span>
					<el-select class="list-search-select" v-model="searchForm.schoolId" placeholder="请选择">
						<el-option v-for="(item, index) in schoolList" :key="index" :label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="list-search-col">
					<span class="list-search-confirm" @click="jobHandle('query')" >查询</span>
					<span class="list-search-cancel" @click="jobHandle('reset')" >重置</span>
					<div class="account-data-add">
						<span @click="accountHandle('add')">+ 新增</span>
					</div>
				</div>
			</div>
		</div>
		<div class="account-data-list">
			<div class="data-list-content">
				<el-table :data="tableData">
					<el-table-column width="80px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="account" label="账号">
					</el-table-column>
					<el-table-column prop="userType" label="账号类型">
						<template slot-scope="scope">
							<span>{{typeMapFn(scope.row.userType)}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="collegeName" label="所属学院">
						<template slot-scope="scope">
							<span>{{scope.row.collegeName?scope.row.collegeName:'无'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="orgName" label="所属部门">
						<template slot-scope="scope">
							<span>{{scope.row.orgName?scope.row.orgName:'无'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="schoolName" label="所属校区">
						<template slot-scope="scope">
							<span>{{scope.row.schoolName?scope.row.schoolName:'无'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间">
					</el-table-column>
					<el-table-column prop="publish" label="操作">
						<template slot-scope="scope">
							<div class="list-content-action">
								<span @click="accountHandle('edit', scope)">编辑</span>
								<el-divider v-if="scope.row.id != 1&&scope.row.account != 'admin'" direction="vertical"></el-divider>
								<span v-if="scope.row.id != 1&&scope.row.account != 'admin'" @click="accountHandle('delete', scope)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="common-pageNation-pc">
				<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
					:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
		<div class="">
			<el-dialog @close="closeAccountDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
				:visible.sync="accountVisible">
				<div slot="title" class="dialog-header">
					<span>{{accountTitle}}</span>
					<img @click="closeAccountDialog" src="@/assets/images/pc/common/close.png">
				</div>
				<div class="">
					<el-form :model="accountData" :rules="accountRules" ref="account" class="">
						<el-form-item class="dialog-formitem" label="账号类型:" prop="userType">
							<el-select @change="handleSelect($event)" :disabled="accountData&&accountData.id == 1&&accountData.account == 'admin'?true:false" :popper-append-to-body="false" class="dialog-formitem-select"
								v-model="accountData.userType" placeholder="请选择">
								<el-option v-for="(item, index) in typeList" :key="index" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item v-if="accountData.userType&&accountData.userType == 'COLLEGE_MANAGER'"
							class="dialog-formitem" label="所在学院:" prop="collegeId">
							<el-select :popper-append-to-body="false" class="dialog-formitem-select"
								v-model="accountData.collegeId" placeholder="请选择" :disabled="accountTitle=='编辑账号'">
								<el-option v-for="item in collegeList" :key="item.id" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item class="dialog-formitem" label="所属部门:" prop="orgId" v-if="accountData.userType&&accountData.userType !== 'COLLEGE_MANAGER'">
							<el-select  :popper-append-to-body="false" class="dialog-formitem-select"
								v-model="accountData.orgId" placeholder="请选择">
								<el-option v-for="(item, index) in orgList" :key="index" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						 <el-form-item class="dialog-formitem" label="所在校区:" prop="schoolId" v-if="accountData.userType&&accountData.userType=='SCHOOL_MANAGER'">
							<el-select  :popper-append-to-body="false" class="dialog-formitem-select"
								v-model="accountData.schoolId" placeholder="请选择">
								<el-option v-for="(item, index) in schoolList" :key="index" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item v-if="accountData.userType" class="dialog-formitem" :label="accountData.userType==='SCHOOL_MANAGER'?'登录账号：':'登录工号:'" prop="account">
							<el-input type="text" class="dialog-formitem-ipt" v-model="accountData.account"
								placeholder="请输入">
							</el-input>
						</el-form-item>
						<el-form-item class="dialog-formitem" label="所在校区:" prop="schoolId" v-if="accountData.userType&&accountData.userType!=='SCHOOL_MANAGER'">
							<el-select  :popper-append-to-body="false" class="dialog-formitem-select"
								v-model="accountData.schoolId" placeholder="请选择">
								<el-option v-for="(item, index) in schoolList" :key="index" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<!-- <el-form-item class="dialog-formitem" label="所属部门:" prop="orgId" v-if="accountData.userType == 'SCHOOL_TEACH'">
							<el-select  :popper-append-to-body="false" class="dialog-formitem-select"
								v-model="accountData.orgId" placeholder="请选择">
								<el-option v-for="(item, index) in orgList" :key="index" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item> -->
						<div v-if="accountData.userType == 'SCHOOL_MANAGER'">
							<el-form-item v-if="accountData.userType" class="dialog-formitem" label="登录密码:"
								:prop="accountTitle == '新增账号'?'password':''">
								<el-input :type="isShowPwd?'text':'password'" class="dialog-formitem-ipt"
									v-model="accountData.password" placeholder="请输入">
								</el-input>
								<img @click="showPwd" class="dialog-formitem-eye" :src="isShowPwd?openEye:closeEye" alt="">
							</el-form-item>
							<el-form-item v-if="accountData.userType" class="dialog-formitem" label="确认密码:"
								:prop="accountTitle == '新增账号'?'rePassword':''">
								<el-input :type="isShowRePwd?'text':'password'" class="dialog-formitem-ipt"
									v-model="accountData.rePassword" placeholder="请输入">
								</el-input>
								<img @click="showRePwd" class="dialog-formitem-eye" :src="isShowPwd?openEye:closeEye"
									alt="">
							</el-form-item>
							<span class="dialog-formitem-text">注：密码由6-16位英文字母、数字或符号组合</span>
						</div>
					</el-form>
				</div>
				<span slot="footer" class="dialog-footer flex-item">
					<span @click="closeAccountDialog" class="cancel-btn">取 消</span>
					<span @click="submitAccountDialog" class="submit-btn">确 定</span>
				</span>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import {
		collegeListPc,
		accountListPc,
		accountDeletePc,
		accountDetailPc,
		accountEditPc,
		accountAddPc,
		orgListAllPc
	} from '@/api/pc/user'
	import {
		schoollistAllPc
	} from '@/api/pc/index'
	export default {
		data() {
			const checkPassword = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请输入密码"));
				} else {
					let regx = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![,\.#%'\+\*\-:;^_`]+$)[,\.#%'\+\*\-:;^_`0-9A-Za-z]{6,16}$/
					if (!regx.test(value)) {
						callback(new Error("请输入6-16位英文字母、数字或符号组合密码"));
					} else {
						callback();
					}
				}
				callback();
			};
			const checkRePassword = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请输入确认密码"));
				} else {
					let regx = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![,\.#%'\+\*\-:;^_`]+$)[,\.#%'\+\*\-:;^_`0-9A-Za-z]{6,16}$/
					if (!regx.test(value)) {
						callback(new Error("请输入6-16位英文字母、数字或符号组合密码"));
					} else {
						if (this.accountData.password != this.accountData.rePassword) {
							callback(new Error("输入的新密码和确认密码密码不一致"));
						} else {
							callback();
						}
					}
				}
				callback();
			};
			return {
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				accountVisible: false,
				accountTitle: "",
				accountData: {},
				accountRules: {
					userType: [{
						required: true,
						message: "请选择账号类型",
						trigger: "blur"
					}],
					orgId:[{
						required: true,
						message: "请选择所属部门",
						trigger: "blur"
					}],
					collegeId: [{
						required: true,
						message: "请选择学院",
						trigger: "blur"
					}],
					schoolId:[{
						required: true,
						message: "请选择校区",
						trigger: "blur"
					}],
					account: [{
						required: true,
						message: "请输入账号",
						trigger: "blur"
					}],
					password: [{
						required: true,
						validator: checkPassword,
						trigger: "blur"
					}],
					rePassword: [{
						required: true,
						validator: checkRePassword,
						trigger: "blur"
					}],
				},
				typeList: [{
						value: 'SCHOOL_MANAGER',
						label: "勤工中心"
					},
					{
						value: 'COLLEGE_MANAGER',
						label: "学院老师"
					},
					{
						value: 'SCHOOL_TEACH',
						label: "学校老师"
					},
				],
				orgList:[],
				collegeList: [],
				isShowPwd: false,
				isShowRePwd: false,
				openEye: require("@/assets/images/pc/user/open-eye.png"),
				closeEye: require("@/assets/images/pc/user/close-eye.png"),
				searchForm:{},
				schoolList:[],
				type:"ALL",
				orgAllList:[]
			}
		},
		watch: {
			accountVisible: {
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.$refs["account"].clearValidate()
							this.accountData = {}
						})
					}
				},
				deep: true
			}
		},
		mounted() {
			this.type="ALL"
			this.getAccountList()
			this.getSchoolList()
			this.getOrgList()
		},
		methods: {
			//获取校区列表
			getSchoolList() {
				let params = {
					pageIndex: 1,
					pageSize: 999
				}
				schoollistAllPc(params).then((res) => {
					if (res.code == 0) {
						this.schoolList = res.data
					}
				})
			},
			// 账号类型映射
			typeMapFn(intType) {
				let typeMap = {
					"STUDENT": "学生",
					"SCHOOL_MANAGER": "勤工中心",
					"COLLEGE_MANAGER": "学院老师",
					"SCHOOL_TEACH": "学校老师",
				}
				return typeMap[intType]
			},
			// 获取账号列表
			getAccountList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					...this.searchForm
				}
				this.tableData = [];
				accountListPc(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			currentChange(val) {
				this.pageIndex = val;
				this.getAccountList();
			},
			// 获取学院列表
			getCollegeList() {
				let params = {
					pageIndex: 1,
					pageSize: 999
				}
				collegeListPc(params).then((res) => {
					if (res.code == 0) {
						this.collegeList = res.data
					}
				})
			},
			//点击选择账号
			handleSelect(e){
				console.log(e,'e')
				if(e==='SCHOOL_MANAGER'){
					this.type='SUB_DEPT'
					this.getOrgList()
				}else{
					this.type=""
					this.getOrgList()
				}
			},
			//获取部门列表
			getOrgList() {
				let params = {
					pageIndex: 1,
					pageSize: 999,
					type:this.type
				}
				orgListAllPc(params).then((res) => {
					if (res.code == 0) {
						this.orgList = res.data
						if(this.type=="ALL"){
							this.orgAllList = res.data
						}
					}
				})
			},
			jobHandle(type){
				if(type=='reset'){
					this.pageIndex = 1
					this.searchForm = {}
				}
				this.getAccountList();
			},
			accountHandle(type, scope) {
				switch (type) {
					case "add":
						this.getCollegeList()
						// this.getOrgList()
						this.accountVisible = true
						this.accountTitle = "新增账号"
						break;
					case "edit":
						this.getCollegeList()
						if(scope.row.userType=='SCHOOL_MANAGER'){
							this.type='SUB_DEPT'
						}else{
							this.type=""
						}
						this.getOrgList()
						accountDetailPc(scope.row.id, {
							id: scope.row.id
						}).then((res) => {
							if (res.code == 0) {
								this.accountData = res.data
								this.accountVisible = true
								this.accountTitle = "编辑账号"
								this.$forceUpdate()
								
							}
						})
						break;
					case "delete":
						this.$confirm('确定删除当前账号吗？', '信息提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							accountDeletePc(scope.row.id, {
								id: scope.row.id
							}).then((res) => {
								if (res.code == 0) {
									this.$message({
										type: 'success',
										message: '删除成功!'
									});
									this.pageIndex = 1;
									this.getAccountList();
								}
							})
						}).catch(() => {});
						break;
				}
			},
			closeAccountDialog() {
				this.accountVisible = false
			},
			submitAccountDialog() {
				this.$refs["account"].validate(valid => {
					if (valid) {
						let params = JSON.parse(JSON.stringify(this.accountData))
						this.$set(params, "name", `用户-${params.account}`)
						if (params.userType != 'COLLEGE_MANAGER') {
							delete params.collegeId
						}
						let messageText = ""
						console.log("params", params)
						if (this.accountTitle == '新增账号') {
							messageText = "新增账号成功~"
							accountAddPc(params).then(res => {
								if (res.code == 0) {
									this.$message({
										message: messageText,
										type: 'success',
										duration: 1500
									})
									this.accountVisible = false
									this.pageIndex = 1
									this.getAccountList();
								}
							})
						} else {
							if(params.password) {
								let regx = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![,\.#%'\+\*\-:;^_`]+$)[,\.#%'\+\*\-:;^_`0-9A-Za-z]{6,16}$/
								if(!regx.test(params.password)) {
									this.$message({
										message: "请输入6-16位英文字母、数字或符号组合密码",
										type: 'error',
										duration: 1500
									})
									return
								} else {
									if(!params.rePassword) {
										this.$message({
											message: "请输入确认密码",
											type: 'error',
											duration: 1500
										})
										return
									} else {
										let regx = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![,\.#%'\+\*\-:;^_`]+$)[,\.#%'\+\*\-:;^_`0-9A-Za-z]{6,16}$/
										if (!regx.test(params.password)) {
											this.$message({
												message: "请输入6-16位英文字母、数字或符号组合确认密码",
												type: 'error',
												duration: 1500
											})
											return
										} else {
											if (params.rePassword != params.password) {
												this.$message({
													message: "输入的新密码和确认密码密码不一致",
													type: 'error',
													duration: 1500
												})
												return
											}
										}
									}
								}
							}
							messageText = "编辑账号成功~"
							accountEditPc(params).then(res => {
								if (res.code == 0) {
									this.$message({
										message: messageText,
										type: 'success',
										duration: 1500
									})
									this.accountVisible = false
									this.pageIndex = 1
									this.getAccountList();
								}
							})
						}
					}
				})
			},
			showPwd() {
				this.isShowPwd = !this.isShowPwd
			},
			showRePwd() {
				this.isShowRePwd = !this.isShowRePwd
			},
		},
	}
</script>
<style lang="scss" scoped>
	/deep/.dialog-formitem .el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
	}
	/deep/.list-search-col .el-select .el-input .el-select__caret {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	/deep/.list-search-col .el-input__inner {
		height: 32px;
		border-color: #DCDFE6;
		border-radius: 0px 2px 2px 0px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
	}
	/deep/.data-list-content .el-table th.el-table__cell>.cell {
		text-align: center;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #909399;
		line-height: 40px;
		background: #F7F8F9;
	}

	/deep/.data-list-content .el-table .el-table__cell {
		padding: 0 0;
	}

	/deep/.data-list-content .el-table .cell {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/deep/.el-divider--vertical {
		background-color: #4392FA;
	}

	/deep/.el-dialog__header {
		padding: 0 24px !important;
		background: #F7F8F9;
		border-radius: 4px 4px 0px 0px;
	}

	/deep/.el-form-item__label {
		line-height: 36px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #909399;
	}
	.data-list-search {
		display: flex;
		flex-direction: column;

		.list-search-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 8px;

			.list-search-col {
				width: 300px;
				display: flex;
				align-items: center;
				padding-right: 10px;
				box-sizing: border-box;

				.list-search-label {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 32px;
					color: #909399;
				}

				.list-search-ipt {
					height: 32px;
					line-height: 32px;
					flex: 1;
				}

				.list-search-date {
					flex: 1;
					height: 32px;
				}

				.list-search-select {
					flex: 1;
					height: 32px;
				}

				.list-search-confirm {
					width: 68px;
					line-height: 32px;
					background: #4392FA;
					border-radius: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					margin-right: 8px;
					cursor: pointer;
				}

				.list-search-cancel {
					width: 68px;
					line-height: 32px;
					border: 1px solid #4392FA;
					border-radius: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #4392FA;
					cursor: pointer;
				}

				.list-search-download {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 90px;
					height: 32px;
					border: 1px solid #DCDFE6;
					border-radius: 2px;
					margin-left: auto;
					cursor: pointer;

					.search-download-icon {
						width: 14px;
						height: 14px;
						margin-right: 4px;
					}

					.search-download-text {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #606266;
					}
				}
			}
		}
	}
	.flex-item {
		display: flex;
		align-items: center;
	}

	.submit-btn {
		width: 72px;
		height: 33px;
		background: #4392FA;
		border-radius: 2px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.cancel-btn {
		width: 72px;
		height: 33px;
		border: 1px solid #4392FA;
		border-radius: 2px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #4392FA;
		margin-left: auto;
		margin-right: 16px;
		box-sizing: border-box;
	}

	.account {
		.account-data-add {
			margin-left:10px;
			span {
				width: 80px;
				height: 32px;
				border: 1px solid #4392FA;
				border-radius: 2px;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #4392FA;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
		}

		.account-data-list {
			display: flex;
			flex-direction: column;

			.data-list-content {
				border: 1px solid #DCDFE6;
				border-radius: 2px;
				margin-top: 16px;

				.list-content-action {
					display: flex;
					align-items: center;
					justify-content: center;

					span {
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 48px;
						color: #4392FA;
						cursor: pointer;
					}
				}
			}
		}
	}

	.dialog-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 50px;

		span {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: bold;
			line-height: 50px;
			color: #303133;
			display: flex;
			align-items: center;
		}

		img {
			width: 15px;
			height: 15px;
			cursor: pointer;
		}
	}

	.dialog-formitem {
		width: 100%;
		display: flex;
		align-items: center;
		position: relative;

		.dialog-formitem-ipt {
			flex: 1;
			border: 1px solid #DCDFE6;
			margin-right: 18px;
			min-width: 320px;
		}

		.dialog-formitem-eye {
			width: 16px;
			height: 16px;
			cursor: pointer;
			position: absolute;
			right: 10px;
			top: 10px;
		}

		.dialog-formitem-textarea {
			flex: 1;
			border: 1px solid #DCDFE6;
			min-width: 320px;
		}

		.dialog-formitem-select {
			flex: 1;
			border: 1px solid #DCDFE6;
			min-width: 340px;
		}
	}

	.dialog-formitem-text {
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #909399;
		word-break: break-all;
		line-height: 18px;
	}
</style>
